dmx.Component('smooth-scroll', {

    attributes: {
        selector: { // must be a valid CSS selector
            type: String,
            default: 'a[href*="#"]'
        },

        ignore: { // must be a valid CSS selector
            type: String,
            default: '[data-scroll-ignore]'
        },

        header: { // must be a valid CSS selector
            type: String,
            default: null
        },

        speed: {
            type: Number,
            default: 500
        },

        offset: {
            type: Number,
            default: 0
        },

        easing: { // Linear, easeInQuad, easeInCubic, easeInQuart, easeInQuint, easeInOutQuad, easeInOutCubic, easeInOutQuart, easeInOutQuint, easeOutQuad, easeOutCubic, easeOutQuart, easeOutQuint
            type: String,
            default: 'easeInOutCubic'
        }
    },

    methods: {
        goto: function(location) { // location is selector or number
            if (typeof location == 'string') {
                location = document.querySelector(location);
            }
            this.scroll.animateScroll(location);
        }
    },

    render: function(node) {
        this.update({});
    },

    update: function(props) {
        if (JSON.stringify(this.props) != JSON.stringify(props)) {
            if (this.scroll) this.scroll.destroy();
            this.scroll = new SmoothScroll(this.props.selector, this.props);
        }
    },

    destroy: function() {
        this.scroll.destroy();
    }

});
